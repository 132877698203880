<template>
  <a
    :href="href"
    :data-test="`nav-link-${to.name}`"
    :class="isActive ? activeClass : inactiveClass"
    :aria-current="isExactActive ? 'page' : null"
    @click="navigate"
  >
    <slot :is-exact-active-class="isExactActive" :is-active-class="isActive" />
  </a>
</template>

<script setup lang="ts">
  import { RouterLink, useLink } from 'vue-router'

  const props = defineProps({
    ...RouterLink.props,
    inactiveClass: { type: String, default: '' },
    activeClass: { type: String, default: '' },
  })

  const { href, isActive, isExactActive, navigate } = useLink(props)
</script>
