import { createGtm } from '@gtm-support/vue-gtm'
import type { App } from 'vue'

import router from '../router'

const { VITE_GTM } = import.meta.env

export const useGtm = (app: App) => {
  if (!VITE_GTM) {
    return
  }

  const gtm = createGtm({
    id: VITE_GTM,
    defer: true,
    compatibility: false,
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: true,
  })

  app.use(gtm)
}
