<template>
  <router-link
    v-if="(!loading && isNotSubscribed) || show"
    :to="{ name: 'Credits' }"
    class="relative flex items-center space-x-1.5 py-2 px-4 rounded-full text-stone-300 bg-gray-700 text-sm"
  >
    <StarIcon class="h-5 w-5" />
    <span class="whitespace-nowrap">{{ tweened.number }} credits</span>

    <span
      v-if="credits < 100"
      class="absolute -right-2 -top-2 bg-gray-800 rounded-full overflow-hidden"
    >
      <ExclamationCircleIcon class="text-red-700 h-7 w-7" />
    </span>
  </router-link>
</template>

<script setup lang="ts">
  import { StarIcon } from '@heroicons/vue/24/outline'
  import { ExclamationCircleIcon } from '@heroicons/vue/24/solid'
  import { useLazyQuery, useSubscription } from '@vue/apollo-composable'
  import { computed, watch } from 'vue'

  import { useCheckSubscription } from '@/composables/useCheckSubscription'
  import { useNumberTweener } from '@/composables/useNumberTweener'
  import { MeDocument, OnUpdateUserCreditsDocument } from '@/graphqlTypes'
  import { useUser } from '@/stores/user'

  const { loading, isNotSubscribed } = useCheckSubscription()

  interface IProps {
    show?: boolean
  }
  defineProps<IProps>()

  const { result, load } = useLazyQuery(MeDocument)
  const credits = computed(() => {
    return result.value?.me.shares.credits ?? 0
  })

  const store = useUser()
  if (store.state.tenantId) {
    load()
    subscribeToCreditsUpdate()
  } else {
    watch(
      () => store.state.tenantId,
      () => {
        load()
        subscribeToCreditsUpdate()
      },
    )
  }

  function subscribeToCreditsUpdate() {
    if (isNotSubscribed.value) {
      useSubscription(OnUpdateUserCreditsDocument, { userId: store.state.tenantId })
    }
  }

  const { tweened } = useNumberTweener(credits)
</script>
