<template>
  <transition
    enter-active-class="transition ease-out duration-100"
    enter-from-class="transform opacity-0 scale-95"
    enter-to-class="transform opacity-100 scale-100"
    leave-active-class="transition ease-in duration-75"
    leave-from-class="transform opacity-100 scale-100"
    leave-to-class="transform opacity-0 scale-95"
    appear
  >
    <slot />
  </transition>
</template>

<script setup lang="ts">
  import { useSlots } from 'vue'

  const slots = useSlots()

  if (!slots.default) {
    // eslint-disable-next-line no-console
    console.error('Default slot not found.')
  }
</script>
