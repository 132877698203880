import gsap from 'gsap'
import { reactive, type Ref, watch } from 'vue'

export const useNumberTweener = (number: Ref<number>, options = { duration: 0.5 }) => {
  const tweened = reactive({ number: 0 })
  watch(
    number,
    (n) => {
      gsap.to(tweened, { duration: options.duration, number: Number(n) })
    },
    { immediate: true },
  )

  watch(tweened, (obj) => (obj.number = parseInt(obj.number.toFixed(0))))

  return { tweened }
}
