import { Amplify } from '@aws-amplify/core'
//Amplify.Logger.LOG_LEVEL = 'DEBUG'

const { VITE_POOL_ID, VITE_POOL_WEB_CLIENT_ID, VITE_REGION } = import.meta.env

const aws_exports = {
  aws_project_region: VITE_REGION,
  aws_cognito_region: VITE_REGION,
  aws_user_pools: 'enable',
  aws_user_pools_id: VITE_POOL_ID,
  aws_user_pools_web_client_id: VITE_POOL_WEB_CLIENT_ID,
}

Amplify.configure(aws_exports)
