import { createRouter, createWebHistory } from 'vue-router'

import { usePostHog } from '@/plugins/usePostHog'

import { authGuard } from './auth.guard'
import { pageTitleMiddleware } from './pageTitle.middleware'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(),
  routes,
})

const { posthog } = usePostHog()
router.afterEach(() => {
  posthog.capture('$pageview')
})
router.beforeEach((to, from) => {
  if (from.path !== to.path) {
    posthog.capture('$pageleave')
  }
})

router.beforeEach(authGuard)
router.beforeEach(pageTitleMiddleware)

export default router
