<template>
  <div
    class="fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end"
  >
    <transition
      enter-active-class="transition transform ease-out duration-300"
      enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
      leave-active-class="transition ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="store.active"
        class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto"
      >
        <div class="rounded-lg shadow-xs overflow-hidden">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <CheckIcon v-if="store.nextElement.status" class="h-6 w-6 text-green-400" />
                <XMarkIcon v-if="!store.nextElement.status" class="h-6 w-6 text-red-400" />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p
                  v-if="store.nextElement.title"
                  class="text-sm leading-5 font-medium text-gray-900"
                >
                  {{ store.nextElement.title }}
                </p>
                <p v-if="store.nextElement.desc" class="mt-1 text-sm leading-5 text-gray-500">
                  {{ store.nextElement.desc }}
                </p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button
                  class="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                  @click="closeAction"
                >
                  <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
  import { CheckIcon, XMarkIcon } from '@heroicons/vue/24/outline'
  import { ref, watch } from 'vue'

  import { useNotification } from '@/stores/notification'

  const timeout = ref<NodeJS.Timeout>()
  const store = useNotification()

  watch(
    () => store.active,
    (value) => {
      if (value) {
        timeout.value = setTimeout(() => {
          store.close()
        }, 5 * 1000)
      }
    },
  )

  const closeAction = () => {
    store.close()
    if (timeout.value !== null) clearTimeout(timeout.value)
  }
</script>
