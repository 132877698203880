<script lang="ts" setup>
  import { ExclamationCircleIcon } from '@heroicons/vue/20/solid'
  import { useQuery } from '@vue/apollo-composable'
  import { computed } from 'vue'

  import { SearchGroupsDocument } from '@/graphqlTypes'
  import { useUser } from '@/stores/user'

  const store = useUser()

  const { loading, result } = useQuery(SearchGroupsDocument, {
    text: 'skedr',
    perPage: 1,
    page: 1,
    userId: store.state.tenantId,
  })

  const showBanner = computed(() => {
    const groupIds = result.value?.searchGroups.groups.map(({ id }) => id) ?? []
    return (
      !loading.value &&
      groupIds.filter((id) => id === '14835830@N25' || id === '14862942@N24').length !== 1
    )
  })
</script>

<template>
  <BaseBanner v-if="showBanner" ref="banner" class="text-sm leading-6 items-center">
    <template #icon><ExclamationCircleIcon /></template>
    <strong class="font-semibold">
      We're looking for amazing creative from the Skedr community to feature in our blogs and
      Instagram.
    </strong>
    <br />
    Get involved in our
    <a href="https://www.flickr.com/groups/14862942@N24/" class="underline" target="_blank">
      Skedr group
    </a>
    or
    <a href="https://www.flickr.com/groups/14835830@N25/" class="underline" target="_blank">
      Skedr virtual photography group
    </a>
    <span aria-hidden="true">&rarr;</span>
  </BaseBanner>
</template>
