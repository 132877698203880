import { cva, type VariantProps } from 'class-variance-authority'

export { default as Avatar } from './Avatar.vue'
export { default as AvatarFallback } from './AvatarFallback.vue'
export { default as AvatarImage } from './AvatarImage.vue'

export const avatarVariant = cva(
  'flex items-center justify-center font-normal select-none shrink-0 overflow-hidden border',
  {
    variants: {
      size: {
        xs: 'h-9 w-9 text-xs',
        sm: 'h-12 w-12 text-xs',
        base: 'h-16 w-16 text-2xl',
        lg: 'h-24 w-24 text-4xl',
      },
      shape: {
        circle: 'rounded-full',
        square: 'rounded-md',
      },
    },
  },
)

export type AvatarVariants = VariantProps<typeof avatarVariant>
