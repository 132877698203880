<template>
  <div class="min-h-screen bg-white flex">
    <div
      class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24"
    >
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div class="flex space-x-2 items-end">
          <img class="h-16" src="/MainPositiveLogo.svg" alt="Skedr" />
        </div>

        <router-view />
      </div>
    </div>
    <div class="group hidden lg:block relative w-0 flex-1">
      <img
        class="absolute inset-0 h-full w-full object-cover"
        src="/Rails_to_Carsten_Skodell.jpg"
        alt="Photo by Carsten Skodell"
      />
      <div
        class="absolute left-0 inset-y-0 grid content-center bg-gray-700 bg-opacity-40 w-full transition duration-500 ease-in-out delay-150 group-hover:bg-opacity-0 overflow-hidden"
      >
        <div class="contents" @mouseenter="over = true" @mouseleave="over = false">
          <router-view
            v-slot="{ Component }"
            name="side"
            class="transition duration-500 delay-150 ease-in-out"
            :class="{ 'group-hover:-translate-x-3/4': !over }"
          >
            <transition
              appear
              mode="out-in"
              enter-active-class="transition-opacity ease-in-out duration-200"
              enter-from-class="opacity-0"
              enter-to-class="opacity-100"
              leave-active-class="transition-opacity ease-in-out duration-200"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
      </div>
      <div
        class="absolute bottom-0 left-0 text-white bg-black group-hover:opacity-95 opacity-70 p-3 duration-500 delay-150 ease-in-out rounded-tr-md"
      >
        Photo by
        <a
          href="https://www.flickr.com/photos/okular_dus/48991242321/"
          class="underline"
          target="_blank"
          rel="nofollow"
        >
          Carsten Skodell
        </a>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { ref } from 'vue'

  const over = ref(false)
</script>
