import './index.css'
import '@/configs/Amplify'
import 'floating-vue/dist/style.css'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import './utils/vitePreloadError'

import { DefaultApolloClient } from '@vue/apollo-composable'
import FloatingVue from 'floating-vue'
import { createApp, h, provide } from 'vue'
import VueVirtualScroller from 'vue-virtual-scroller'
import VueApexCharts from 'vue3-apexcharts'

import { useGtm } from '@/configs/GTM'
import { initSentry } from '@/configs/Sentry'
import { apolloClient } from '@/configs/Vue3Apollo'

import App from './App.vue'
import router from './router'
import store from './stores'

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient)
  },
  render: () => h(App),
})

initSentry(app)

app.use(VueVirtualScroller)
app.component('RecycleScroller', VueVirtualScroller.RecycleScroller)
app.use(FloatingVue)
app.use(store)
app.use(router)
app.use(VueApexCharts)
app.mount('#app')

useGtm(app)
