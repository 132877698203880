import { onMounted, ref, useTemplateRef } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export const useSearch = () => {
  const route = useRoute()
  const router = useRouter()
  const searchTerm = ref('')
  const searchBar = useTemplateRef('searchBar')

  onMounted(() => {
    if (route.params.searchTerm) {
      searchTerm.value = route.params.searchTerm as string
      // console.log(searchBar)
      // if (searchBar.value) searchBar.value.focus()
    }
  })

  const onKeyUpSearch = () => {
    router.push({ name: 'SearchGroups', params: { searchTerm: searchTerm.value } })
  }

  return { searchBar, searchTerm, onKeyUpSearch }
}
