<template>
  <div v-if="mobile" class="flex items-center px-5">
    <div class="flex-shrink-0">
      <Avatar shape="circle" size="sm">
        <AvatarImage :src="me.buddyIcon" :alt="me.userName?.toString()" />
        <AvatarFallback>{{ me.userName?.substring(0, 3) }}</AvatarFallback>
      </Avatar>
    </div>
    <div class="ml-3 min-w-0 flex-1">
      <div class="text-base font-medium text-gray-800 truncate">{{ me.userName }}</div>
      <div class="text-sm font-medium text-gray-500 truncate">{{ me.userId }}</div>
    </div>
    <TheCredits />
  </div>
  <span v-else class="flex min-w-0 items-center justify-between space-x-3">
    <Avatar shape="circle" size="xs" class="border-none">
      <AvatarImage :src="me.buddyIcon" :alt="me.userName?.toString()" />
      <AvatarFallback>{{ me.userName?.substring(0, 3) }}</AvatarFallback>
    </Avatar>
    <span class="flex-1 flex flex-col min-w-0">
      <span
        class="text-sm font-medium truncate"
        :class="{ 'text-black': dark, 'text-white': !dark }"
      >
        {{ me.userName }}
      </span>
      <span class="text-xs truncate" :class="{ 'text-gray-600': dark, 'text-gray-300': !dark }">
        {{ me.userId }}
      </span>
    </span>
  </span>
</template>
<script setup lang="ts">
  import { useLazyQuery } from '@vue/apollo-composable'
  import { computed, watch } from 'vue'

  import { MeDocument } from '@/graphqlTypes'
  import { useUser } from '@/stores/user'

  interface IProps {
    dark?: boolean
    mobile?: boolean
  }
  const { dark = false, mobile = false } = defineProps<IProps>()

  const { result, load } = useLazyQuery(MeDocument)
  const me = computed(() =>
    result.value
      ? result.value.me
      : {
          buddyIcon: 'https://combo.staticflickr.com/pw/images/buddyicon08.png',
          userName: 'Link',
          userId: 'an account',
        },
  )

  const store = useUser()
  if (store.state.tenantId) load()
  watch(
    () => store.state.tenantId,
    () => {
      load()
    },
  )
</script>
