// src/plugins/usePostHog.ts
import posthog from 'posthog-js'

const { VITE_POSTHOG_KEY } = import.meta.env

export function usePostHog() {
  posthog.init(VITE_POSTHOG_KEY, {
    api_host: 'https://eu.i.posthog.com',
    capture_pageview: false,
  })

  return {
    posthog,
  }
}
