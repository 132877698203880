const useBeacon = () => {
  const openBeacon = () => {
    if (window.Beacon) {
      window.Beacon('toggle')
    }
  }
  return { openBeacon }
}

export { useBeacon }
