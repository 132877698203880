import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

interface IMessage {
  title: string
  desc?: string
  status?: boolean
}

export const useNotification = defineStore('notification', () => {
  const stack = ref<string[]>([])

  const nextElement = computed(() => (stack.value[0] ? JSON.parse(stack.value[0]) : undefined))
  const active = computed(() => (nextElement.value ? true : false))

  function success(payload: IMessage) {
    payload.status = true
    add(payload)
  }

  function error(payload: IMessage) {
    payload.status = false
    add(payload)
  }

  async function close() {
    await new Promise((r) => setTimeout(r, 500))
    stack.value.shift()
    if (stack.value.length > 0) {
      await new Promise((r) => setTimeout(r, 500))
    }
  }

  function add(message: IMessage) {
    const parsedMess = JSON.stringify(message)
    if (stack.value.indexOf(parsedMess) === -1) {
      stack.value.push(parsedMess)
    }
  }

  return {
    stack,
    active,
    nextElement,
    success,
    error,
    close,
  }
})
