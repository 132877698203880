import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client/core'
import { Auth } from '@aws-amplify/auth'
import { createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'

const { VITE_GRAPHQL_URL: url, VITE_REGION: region } = import.meta.env
const auth = {
  type: 'AMAZON_COGNITO_USER_POOLS',
  jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
}
// HTTP connection to the API
const httpLink = new HttpLink({ uri: url })

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
])

const cache = new InMemoryCache({
  typePolicies: {
    ScheduledPhoto: { keyFields: ['groupId', 'photoId'] },
    User: { keyFields: ['userId'] },
  },
  possibleTypes: {
    Log: ['SharePhotoSuccessLog', 'SharePhotoErrorLog', 'ScheduledPhotoLog'],
  },
})

// Create the apollo client
const apolloClient = new ApolloClient({
  link,
  cache,
  connectToDevTools: true,
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all',
    },
  },
})

export { apolloClient }
