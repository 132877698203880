import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

import { useUser } from '@/stores/user'

async function authGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  const { checkUser } = useUser()
  const isProtected = to.matched.some((route) => route.meta.protected)
  const isUserLogged = await checkUser()
  if (isProtected) {
    if (isUserLogged) {
      next()
    } else {
      next({ name: 'Signin', query: { redirect: to.fullPath } })
    }
  } else {
    if (isUserLogged) {
      next({ name: 'Account' })
    } else {
      next()
    }
  }
}

export { authGuard }
