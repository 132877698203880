import { useQuery } from '@vue/apollo-composable'
import { DateTime } from 'luxon'
import { computed } from 'vue'

import { SubscriptionStatus, UserSubscriptionInformationDocument } from '@/graphqlTypes'

export const SubscriptionTypes = {
  ...SubscriptionStatus,
  ActiveUntil: 'active_until',
}

const useCheckSubscription = () => {
  const { result, loading } = useQuery(UserSubscriptionInformationDocument)

  const subscriptionValidUntil = computed(() => result.value?.me.subscriptionValidUntil)

  const subscriptionState = computed(() => {
    if (!result.value) return null

    if (result.value.me.subscriptionState?.state) {
      if (
        result.value.me.subscriptionValidUntil &&
        result.value.me.subscriptionState.state !== SubscriptionTypes.Active
      ) {
        const today = DateTime.now()
        const d2 = DateTime.fromISO(result.value.me.subscriptionValidUntil)
        return today < d2 ? SubscriptionTypes.ActiveUntil : null
      }
      return result.value.me.subscriptionState.state
    }
    return null
  })

  const isNotSubscribed = computed(() => {
    return subscriptionState.value === null || subscriptionState.value === SubscriptionTypes.Deleted
  })

  const isSubscribed = computed(() => {
    const validStates = [SubscriptionTypes.Active, SubscriptionTypes.ActiveUntil]
    return validStates.indexOf(subscriptionState.value ? subscriptionState.value : '') > -1
  })

  const creditsLeft = computed(() => result.value?.me?.shares.credits ?? 0)

  const userCanShareImages = computed(() => {
    return isSubscribed.value || (isNotSubscribed.value && creditsLeft.value > 0)
  })

  const isSubscriptionPastDue = computed(
    () => subscriptionState.value === SubscriptionStatus.PastDue,
  )

  return {
    result,
    loading,
    creditsLeft,
    isNotSubscribed,
    isSubscribed,
    subscriptionValidUntil,
    subscriptionState,
    userCanShareImages,
    isSubscriptionPastDue,
  }
}
export { useCheckSubscription }
